<template>
  <div>
    <CCol v-show="empresas.length <= 0" sm="12" md="12" lg="12">
      <CCard accent-color="secondary">
        <CCardHeader class="text-center">
          <CRow>
            <CCol>Seleccione un periodo para mostrar información</CCol>
          </CRow>
        </CCardHeader>
      </CCard>
    </CCol>
    <CCol v-bind:key="empresa.codEmpresa" v-for="empresa in empresas">
      <CRow class="mt-3">
        <CCol>
          <CCard accent-color="secondary" class="mt-1 mb-1">
            <CCardHeader style="align-items: center">
              <div
                class="card-header-actions float-left"
                :content="
                  'Total trabajadores: ' + empresa.infoEmpresa.totalTrabajadores
                "
                v-tippy="{
                  placement: 'top',
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'google',
                  followCursor: true,
                }"
              >
                <CLink
                  class="card-header-action btn-minimize float-right"
                  @click="desplegarEmpresa(empresa)"
                >
                  <CIcon
                    :name="`cil-chevron-${
                      empresa.desplegada ? 'bottom' : 'top'
                    }`"
                  />
                </CLink>
              </div>

              <CRow>
                <CCol md="12"
                  ><!--Datos empresa-->
                  <CRow>
                    <CCol md="3">
                      <div>
                        <a
                          style="text-decoration: none; color: #0b7689"
                          class="ml-1 mr-1"
                          content="Ver Info Empresa"
                          v-tippy="{
                            placement: 'top',
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                            theme: 'google',
                            followCursor: true,
                          }"
                          @click="mostrarInfoEmpresa(empresa)"
                        >
                          <!--<CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />-->
                          <span
                            class="pi pi-info-circle"
                            style="font-size: 1rem; color: #0b7689"
                          ></span>
                        </a>
                      </div>
                    </CCol>
                    <CCol md="3">
                      <span
                        style="vertical-align: middle"
                        v-tippy="{
                          placement: 'top',
                          arrow: true,
                          arrowType: 'round',
                          animation: 'fade',
                          theme: 'google',
                          followCursor: true,
                          content: 'Nombre Empresa',
                        }"
                        >({{ empresa.codEmpresa }}) {{ empresa.nombreEmpresa }}
                        {{ empresa.idEmpresa }}
                      </span>
                    </CCol>
                    <CCol md="3">
                      <span
                        style="vertical-align: middle"
                        v-tippy="{
                          placement: 'top',
                          arrow: true,
                          arrowType: 'round',
                          animation: 'fade',
                          theme: 'google',
                          followCursor: true,
                          content: 'Rut Empresa',
                        }"
                      >
                        {{ $utils.formatearRut(empresa.rutEmpresa) }}
                      </span>
                    </CCol>
                    <CCol md="3" style="vertical-align: middle">
                      <span>
                        <CBadge
                          class="m-1"
                          style="font-size: 0.7rem; font-weight: normal"
                          :content="
                            empresa.estado == 1
                              ? 'En Revisón'
                              : empresa.estado == 2
                              ? 'Rechazado ' +
                                empresa.mensajeRespuesta +
                                ' (' +
                                $utils.formatFechaEnDistancia(
                                  empresa.fechaMensaje
                                ) +
                                ')'
                              : empresa.estado == 3
                              ? 'Aceptado'
                              : 'Sin Enviar'
                          "
                          v-tippy="{
                            placement: 'top',
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                            theme: 'google',
                          }"
                          shape="pill"
                          :color="
                            empresa.estado == 1
                              ? 'info'
                              : empresa.estado == 2
                              ? 'danger'
                              : empresa.estado == 3
                              ? 'success'
                              : 'secondary'
                          "
                        >
                          <CIcon
                            v-if="empresa.estado == 1"
                            style="width: 20px; height: 20px"
                            :content="$options.freeSet.cilArrowCircleRight"
                          />
                          <CIcon
                            v-else-if="empresa.estado == 2"
                            style="width: 20px; height: 20px"
                            :content="$options.freeSet.cilXCircle"
                          />
                          <CIcon
                            v-else-if="empresa.estado == 3"
                            style="width: 20px; height: 20px"
                            :content="$options.freeSet.cilCheckCircle"
                          />
                          <CIcon
                            v-else
                            style="width: 20px; height: 20px"
                            :content="$options.freeSet.cilBan"
                          />
                        </CBadge>
                        <CBadge
                          v-show="empresa.periodoCerrado"
                          class="m-1"
                          :content="`Periodo Cerrado ${
                            empresa.fechaPeriodoCerrado == undefined ||
                            empresa.fechaPeriodoCerrado == ''
                              ? 'por aplicación Edig'
                              : $utils.formatFechaEnDistancia(
                                  empresa.fechaPeriodoCerrado
                                )
                          }`"
                          v-tippy="{
                            placement: 'top',
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                            theme: 'google',
                          }"
                          shape="pill"
                          style="
                            font-size: 0.7rem;
                            font-weight: normal;
                            background-color: #29235c;
                            color: white;
                          "
                        >
                          <CIcon
                            style="width: 20px; height: 20px"
                            :content="$options.freeSet.cilLockLocked"
                          />
                        </CBadge>

                        <CButton
                          v-show="
                            tipoDocumento.valor != 'contratos' &&
                            !empresa.periodoCerrado &&
                            empresa.estado == 3
                          "
                          shape="pill"
                          content="Cerrar Periodo"
                          v-tippy="{
                            placement: 'top',
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                            theme: 'google',
                          }"
                          style="font-size: 11px; padding: 1px"
                          class="btnPrincipalVapp"
                          @click="cerrarPeriodo(empresa)"
                        >
                          <CIcon
                            style="width: 20px; height: 20px"
                            :content="$options.freeSet.cilDoor"
                          />
                        </CButton>
                        <CButton
                          v-show="empresa.periodoCerrado && empresa.estado == 3"
                          shape="pill"
                          content="Abrir Periodo"
                          v-tippy="{ placement: 'left' }"
                          style="font-size: 11px; padding: 1px"
                          class="btnPrincipalVapp"
                          @click="abrirPeriodo(empresa)"
                        >
                          <CIcon
                            style="width: 20px; height: 20px"
                            :content="$options.freeSet.cilRoom"
                          />
                        </CButton>
                      </span>
                    </CCol>
                  </CRow>
                </CCol>
                <!--<CCol>
                                                    {{$utils.formatearRut(empresa.rutEmpresa)}}
                                                </CCol>-->
                <!-- <CCol>
                    <CDropdown
                    color="drpdwn-menu"
                    class="m-0 d-inline-block"
                    size="sm"
                  > 
                    <template #toggler-content>
                      <CIcon :content="$options.freeSet.cilApplications" />
                    </template>
                    <div role="group">
                      <CDropdownHeader class="font-weight-bold">
                        <span
                          class="pi pi-info-circle"
                          style="font-size: 1rem; color: #0b7689"
                        ></span>
                        No hay informacion para ser Aceptada
                      </CDropdownHeader>
                    </div>
                    <div role="group">
                      <CDropdownHeader class="font-weight-bold">
                        Pensar que colocar
                      </CDropdownHeader>
                      <CDropdownItem v-show="usaPortalEmpresa">
                        <span
                          v-show="empresa.userNotificar.length == 0"
                          style="color: red"
                          >!</span
                        >
                        {{
                          empresa.estado == 3
                            ? "Volver a Enviar"
                            : "Enviar a Revisión"
                        }}
                      </CDropdownItem>

                      <CDropdownItem
                        v-show="
                          !empresa.enviandoEmpresa &&
                          empresa.estado != 1 &&
                          empresa.estado != 3 &&
                          empresa.infoEmpresa.totalTrabajadores > 0 &&
                          !empresa.enviarEmpresa
                        "
                      >
                        Aceptar
                      </CDropdownItem>
                    </div>
                    <CDropdownItem>Opcion2</CDropdownItem>
                    <CDropdownItem>Cancelar revision Empresa</CDropdownItem>
                  </CDropdown> 
                </CCol> -->
                <!-- <CCol>
                   <CDropdown class="float-left" :caret="false">
                    <template #toggler>
                      <CLink class="card-header-action btn-setting">
                        <CIcon :content="$options.freeSet.cilSettings" />
                      </CLink>
                    </template>
                    <div role="group">
                      <CDropdownHeader>Titulo</CDropdownHeader>
                      <CDropdownItem>
                        <CIcon
                          :content="$options.freeSet.cilDescription"
                          class="mr-2"
                        />
                        Ver
                      </CDropdownItem>
                      <CDropdownItem>
                        <CIcon
                          :content="$options.freeSet.cilCloudDownload"
                          class="mr-2"
                        />
                        Descargar
                      </CDropdownItem>
                      <CDropdownDivider />
                    </div>
                  </CDropdown>
                </CCol> -->
              </CRow>
            </CCardHeader>
            <CCollapse :show="empresa.desplegada" :duration="400">
              <CCardBody>
                <CRow v-show="!empresa.isLoadingTrabajadores">
                  <CCol md="12">
                    <CRow>
                      <CCol md="4">
                        <FiltroTrabajadoresComponente ref="filtroTrab2"
                          :empresaSelected="empresa"
                          :tipoDocSelected="tipoDocumento"
                          :mesSelected="mesSeleccionado"
                          :anioSelected="periodoSeleccionado"
                          @seleccionarTrab="
                            filtrarTrabajadores($event, empresa)
                          "
                        />
                      </CCol>
                      <CCol md="3">
                        <OpcionesFiltrosTrabajadores
                          :opcionesFiltrosTrabajadores="
                            empresa.opcionesFiltrosTrabajadores
                          "
                          @seleccionarFiltro="seleccionFiltro($event, empresa)"
                        />
                      </CCol>
                      <CCol md="4">
                        <CDropdown
                          color="drpdwn-menu"
                          class="mt-1"
                          size="sm"
                          v-show="empresa.trabajadores.length > 0"
                        >
                          <template #toggler-content>
                            <!--:disabled="empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando"-->
                            <CIcon
                              :class="
                                empresa.isActualizandoDocumentos ||
                                empresa.isLoadingEnvioTodos ||
                                empresa.isLoadingGenerarDocumento ||
                                empresa.isDescargando
                                  ? 'imgr'
                                  : ''
                              "
                              :content="$options.freeSet.cilApplications"
                            />
                            <!--<CIcon :content="$options.freeSet.cilOptions" />-->
                          </template>
                          <div role="group">
                            <CDropdownHeader
                              v-show="empresa.periodoCerrado"
                              class="font-weight-bold"
                              content="Opciones deshabilitadas por que el periodo se encuentra cerrado"
                              v-tippy="{
                                placement: 'top',
                                arrow: true,
                                arrowType: 'round',
                                animation: 'fade',
                                theme: 'google',
                                followCursor: true,
                              }"
                            >
                              <CIcon
                                style="color: #f9b115"
                                :content="$options.freeSet.cilWarning"
                              />
                              Hay Opciones deshabilitadas </CDropdownHeader
                            ><!--icono Periodo Cerrado-->

                            <CDropdownItem
                              :disabled="
                                empresa.isActualizandoDocumentos ||
                                empresa.isLoadingEnvioTodos ||
                                empresa.isLoadingGenerarDocumento ||
                                empresa.isDescargando ||
                                empresa.estado == 1 ||
                                empresa.estado == 3
                              "
                              v-show="tipoDocumento.permiteSubirDocs"
                              @click="mostrarSubirArchivos(empresa)"
                            >
                              <CIcon
                                class="mr-1"
                                :content="
                                  empresa.periodoCerrado
                                    ? $options.freeSet.cilWarning
                                    : $options.freeSet.cilCloudUpload
                                "
                              />
                              Subir Documentos
                            </CDropdownItem>

                            <CDropdownItem
                              :content="
                                empresa.mostrarMensajeActualizacionDocumentos
                                  ? empresa.mensajeActualizacionDocs
                                  : 'Actualizar Documentos'
                              "
                              v-tippy="{
                                placement: 'top',
                                arrow: true,
                                arrowType: 'round',
                                animation: 'fade',
                                theme: 'google',
                                followCursor: true,
                              }"
                              :disabled="
                                empresa.isActualizandoDocumentos ||
                                empresa.isLoadingEnvioTodos ||
                                empresa.isLoadingGenerarDocumento ||
                                empresa.isDescargando ||
                                empresa.estado == 3
                              "
                              v-show="tipoDocumento.valor == 'liquidaciones'"
                              @click="actualizarDocumentosEmpresa(empresa)"
                            >
                              <CIcon
                                class="mr-1"
                                :class="
                                  empresa.isActualizandoDocumentos ? 'imgr' : ''
                                "
                                :content="
                                  empresa.periodoCerrado
                                    ? $options.freeSet.cilWarning
                                    : $options.freeSet.cilLoopCircular
                                "
                              />
                              {{
                                empresa.isActualizandoDocumentos
                                  ? "Actualizando "
                                  : "Actualizar "
                              }}
                              Documentos

                              <CIcon
                                class="ml-1"
                                v-show="
                                  empresa.mostrarMensajeActualizacionDocumentos
                                "
                                :content="$options.freeSet.cilInfo"
                              />
                            </CDropdownItem>

                            <!--<CDropdownItem 
                                                            v-show="tipoDocumentoSelected.permiteGenerarDoc" 
                                                            :disabled="empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando"
                                                            @click="generarDocumentos(empresa)" >
                                                            <CIcon class="mr-1" 
                                                                :class="(empresa.isLoadingGenerarDocumento)?'imgr':''" 
                                                                :content="$options.freeSet.cilLibraryAdd" /> Generar Documentos
                                                        </CDropdownItem>-->

                            <CDropdownItem
                              :content="empresa.txtEnviarTodos"
                              v-tippy="{
                                placement: 'top',
                                arrow: true,
                                arrowType: 'round',
                                animation: 'fade',
                                theme: 'google',
                                followCursor: true,
                              }"
                              v-show="empresa.hayDocumentosTrabajador"
                              :disabled="
                                empresa.isActualizandoDocumentos ||
                                empresa.isLoadingEnvioTodos ||
                                empresa.isLoadingGenerarDocumento ||
                                empresa.isDescargando
                              "
                              @click="enviaraTodos(empresa)"
                            >
                              <CIcon
                                class="mr-1"
                                :class="
                                  empresa.isLoadingEnvioTodos ? 'imgr' : ''
                                "
                                :content="$options.freeSet.cilEnvelopeLetter"
                              />
                              Enviar Documentos
                            </CDropdownItem>
                          </div>
                          <div
                            role="group"
                            v-show="empresa.hayDocumentosTrabajador"
                          >
                            <CDropdownDivider />
                            <CDropdownHeader class="font-weight-bold">
                              <CIcon
                                class="mr-1"
                                :class="empresa.isDescargando ? 'imgr' : ''"
                                :content="$options.freeSet.cilCloudDownload"
                              />
                              Descarga de Documentos
                            </CDropdownHeader>
                            <CDropdownItem
                              :disabled="
                                empresa.isActualizandoDocumentos ||
                                empresa.isLoadingEnvioTodos ||
                                empresa.isLoadingGenerarDocumento ||
                                empresa.isDescargando
                              "
                              content="Descargar un Archivo con todos los documentos"
                              v-tippy="{
                                placement: 'top',
                                arrow: true,
                                arrowType: 'round',
                                animation: 'fade',
                                theme: 'google',
                                followCursor: true,
                              }"
                              @click="descargarArchivo('merge', empresa)"
                            >
                              <CIcon
                                class="mr-1"
                                :class="empresa.isDescargando ? 'imgr' : ''"
                                :content="$options.freeSet.cilSwapVertical"
                              />
                              Documentos Unificados
                            </CDropdownItem>
                            <CDropdownItem
                              content="Descargar todos los documentos comprimidos"
                              v-tippy="{
                                placement: 'top',
                                arrow: true,
                                arrowType: 'round',
                                animation: 'fade',
                                theme: 'google',
                              }"
                              :disabled="
                                empresa.isActualizandoDocumentos ||
                                empresa.isLoadingEnvioTodos ||
                                empresa.isLoadingGenerarDocumento ||
                                empresa.isDescargando
                              "
                              @click="descargarArchivo('zip', empresa)"
                            >
                              <CIcon
                                class="mr-1"
                                :class="empresa.isDescargando ? 'imgr' : ''"
                                :content="$options.freeSet.cilCompress"
                              />
                              Documentos comprimidos
                            </CDropdownItem>
                          </div>
                        </CDropdown>
                      </CCol>
                    </CRow>
                    <!-- <TablaTrabajadores
                      :empresa="empresa"
                      :tipoDocumentoSelected="tipoDocumento"
                      :mesSelected="mesSeleccionado"
                      :anioSelected="periodoSeleccionado"
                      :totalTrabajadoresDocumentos="
                        empresa.totalTrabajadoresDocumentos
                      "
                      :totalTrabajadores="empresa.totalTrabajadores"
                      :paginacion="empresa.paginacion"
                      :ref="'tblTrabajadores' + empresa.codEmpresa"
                    /> -->
                    <TablaTrabajadoresDocumentos
                      :portContador="true"
                      :empresa="empresa"
                      :periodoCerrado="empresa.periodoCerrado"
                      :periodoSelected="periodoSeleccionado"
                      :mesSelected="mesSeleccionado"
                      :trabajadores="empresa.trabajadores"
                      :loading="false"
                      :paginacion="empresa.paginacion"
                      :tipoDocumentoSelected="tipoDocumento"
                      :totalTrabajadoresDocumentos="
                        empresa.totalTrabajadoresDocumentos
                      "
                      :totalTrabajadores="empresa.totalTrabajadores"
                      :mostrarPeriodo="mostrarPeriodo"
                      @obtenerTrabajadores="getTrabajadores"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCollapse>
          </CCard>
        </CCol>
      </CRow>

      <!-- <ModalInfoEmpresa 
            :mostrarModal="infoEmpresaModal"
            :empresa="empresaSeleccionadaInfo"
            :webPadre="this" 
        /> -->
    </CCol>
  </div>
</template>

<script>
import { freeSet } from "@coreui/icons";

import TablaTrabajadoresDocumentos from "./TablaTrabajadoresDocumentos";
import ModalInfoEmpresa from "./ModalInfoEmpresa";
import FiltroTrabajadoresComponente from "./FiltroTrabajadoresComponente";
import OpcionesFiltrosTrabajadores from "./filtros/OpcionesFiltrosTrabajadores";

export default {
  name: "InfoEmpresa",
  freeSet,
  components: {
    TablaTrabajadoresDocumentos,
    ModalInfoEmpresa,
    FiltroTrabajadoresComponente,
    OpcionesFiltrosTrabajadores,
  },
  props: {
    empresas: Array,
    tipoDocumento: Object,
    mesSeleccionado: Object,
    periodoSeleccionado: Object,
    mostrarPeriodo: Boolean,
  },
  data() {
    return {
      tokenLogin: JSON.parse(this.$cookie.get("userLogginToken")).tokenUsuario,
      tipoUserAdmin: JSON.parse(this.$cookie.get("userLogginToken"))
        .tipoUsuario,
      idCliente: JSON.parse(this.$cookie.get("userLogginToken")).idCliente,
      usaPortalEmpresa: JSON.parse(this.$cookie.get("userLogginToken"))
        .usaPortalEmpresa,

      empresaSeleccionadaInfo: {},
      infoEmpresaModal: false,
    };
  },
  methods: {
    mostrarInfoEmpresa: function (empresa) {
      this.$emit("mostrarInfoEmpresa", empresa);
    },
    desplegarEmpresa: function (empresa) {
      empresa.desplegada = !empresa.desplegada;
      this.getTrabajadores(empresa);
      this.$refs.filtroTrab2.getTrabajadores();
      //let key = "tblTrabajadores" + empresa.codEmpresa;
      //this.getTrabajadores(empresa.desplegada, empresa);
    },
    cerrarPeriodo: function (empresa) {
      this.$emit("cerrarPeriodo", empresa);
    },
    abrirPeriodo: function (empresa) {
      this.$emit("abrirPeriodo", empresa);
    },
    getTrabajadores: function (empresa) {
      this.$emit("obtenerTrabajadores", true, empresa);
    },
    filtrarTrabajadores: function (evento, empresa) {
      empresa.trabajadoresFiltrados = evento;
      empresa.paginacion.current = 1;

      this.getTrabajadores(empresa);
    },
    actualizarFiltro: function () {
      this.$nextTick(() => {
        if (this.$refs.filtroTrab2) {
          this.$refs.filtroTrab2.getTrabajadores();
          console.log(this.$refs.filtroTrab2)
        } else {
          console.error('filtroTrab2 no esta disponible')
        }
      });
    },
  },
};
</script>