<template>
  <Modal
    v-model="mostrarModal"
    :title="'Información Empresa'"
    :modal-style="{ 'min-width': '300px', 'max-width': '400px' }"
    
    @close="cerrarModal"
  >
    <div v-show="empresa!=null">
        <CRow>
            <CCol col="5" class="text-right font-weight-bold mt-2">Estado</CCol>
            <CCol col="7" class="text-left mt-2">
                <CBadge
                  style="font-size:0.7rem; font-weight: normal;"
                  v-tippy="{ 
                      content:(empresa.estado==1)?'En Revisón':(empresa.estado==2)?'Rechazado '+ empresa.mensajeRespuesta+' ('+$utils.formatFechaEnDistancia(empresa.fechaMensaje)+')':(empresa.estado==3)?'Aceptado':'Sin Enviar',
                      placement: 'top',
                      arrow: true, 
                      arrowType: 'round', 
                      animation:'fade',
                      theme: 'google',
                      followCursor : true,
                  }"
                  shape="pill" 
                  :color="(empresa.estado==1)?'info':(empresa.estado==2)?'danger':(empresa.estado==3)?'success':'secondary'">
                  <CIcon v-if="empresa.estado==1" style="width:15px; height:15px;" :content="$options.freeSet.cilArrowCircleRight" />
                  <CIcon v-else-if="empresa.estado==2" style="width:15px; height:15px;" :content="$options.freeSet.cilXCircle" />
                  <CIcon v-else-if="empresa.estado==3" style="width:15px; height:15px;" :content="$options.freeSet.cilCheckCircle" />
                  <CIcon v-else style="width:15px; height:15px;" :content="$options.freeSet.cilBan" />
              </CBadge>
            </CCol>
        </CRow>
        <CRow>
            <CCol col="5" class="text-right font-weight-bold mt-2">Código</CCol>
            <CCol col="7" class="text-left mt-2">
                {{empresa.codEmpresa}}
            </CCol>
        </CRow>
        <CRow>
            <CCol col="5" class="text-right font-weight-bold mt-2">Rut</CCol>
            <CCol col="7" class="text-left mt-2">
                {{empresa.rutEmpresa}}
            </CCol>
        </CRow>
        <CRow>
            <CCol col="5" class="text-right font-weight-bold mt-2">
                Nombre
            </CCol>
            <CCol col="7" class="text-left mt-2">
                {{empresa.nombreEmpresa}}
            </CCol>
        </CRow>
        <CRow>
            <CCol col="5" class="text-right font-weight-bold mt-2">
                Mail Contacto
            </CCol>
            <CCol col="7" class="text-left mt-2">
                {{(empresa.emailEmpresa !=" " && empresa.emailEmpresa!="" && empresa.emailEmpresa!= null)?empresa.emailEmpresa:"Sin Registrar"}}
            </CCol>
        </CRow>
        <CRow>
            <CCol col="5" class="text-right font-weight-bold mt-2">
                Trabajadores Periodo
            </CCol>
            <CCol col="7" class="text-left mt-2">
                {{empresa.totalTrabajadores}}
            </CCol>
        </CRow>
        <CRow>
            <CCol col="5" class="text-right font-weight-bold mt-2">
                Usuarios Notificar
            </CCol>
            <CCol col="7" class="text-left mt-2">
                <CRow v-for="usuario in empresa.userNotificar" v-bind:key="usuario.idUser">
                    <CCol col="8" class="text-left">{{usuario.username}}({{usuario.mailUser}})</CCol>
                </CRow>
            </CCol>
        </CRow>
    </div>
  </Modal>
  
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
  import cons from "@/const";
  import Multiselect from "vue-multiselect";
  import axios from "axios";

  import VueModal from "@kouts/vue-modal";
  import "@kouts/vue-modal/dist/vue-modal.css";

  import { freeSet } from "@coreui/icons";
    import * as util from '@/views/utils/utilsFunctions'

export default {
  name: "ModalPdfViewDocs",
  freeSet,
  props: {
    mostrarModal: Boolean,
    empresa: Object,
    webPadre: Object,
  },
  components: {
    Modal: VueModal,
  },
  data() {
    return {
      tokenLogin: JSON.parse(this.$cookie.get("userLogginToken")).tokenUsuario,


    };
  },
  created: function () {
  },
  beforeMount() {
    let cookieUsername = this.$cookie.get("userLogginToken");
    if (cookieUsername === null || cookieUsername === "") {
      this.$router.push("/pages/login");
    }
    
  },
  methods: {

    cerrarModal: function () {
        this.webPadre.empresaSeleccionadaInfo = {}
        this.webPadre.infoEmpresaModal = false;
    },
  },
};
</script>