<template>
    <CRow>
        <CCol md="12">
            <a-table
                row-key="codEmpresa"
                @expand="expandirEmpresa"
                :columns="columnasEmpresas" 
                :data-source="empresasFiltradas"
                :pagination="paginacionEmpresas"
                :scroll="{ y: 600 }"
                :locale="localeEmpresas"
                class="mt-3" 
                size="small" 
                @change="cambioPaginaEmpresas">

            </a-table>
        </CCol>
    </CRow>
  
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from "@/const";
    import Multiselect from "vue-multiselect";
    import axios from "axios";

    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    import { freeSet } from "@coreui/icons";

    export default {
        name: "TablaEmpresas",
        freeSet,
        props: {
        },
        components: {
            Multiselect
        },
        data() {
            return {
            tokenLogin: JSON.parse(this.$cookie.get("userLogginToken")).tokenUsuario,

                isLoadingEmpresas: false,
                empresasFiltradas: [],
                paginacionEmpresas: { pageSize: 30, hideOnSinglePage: true },
                localeEmpresas: {
                    filterConfirm: 'Filtrar',
                    filterReset: 'Limpiar',
                    emptyText: 'Seleccione un periodo para mostrar información'
                },
                columnasEmpresas: [
                    { slots: { title: 'customTitle' }, scopedSlots: { customRender: 'nombreEmpresa' }, width: '35%', ellipsis: true, onFilter: (value, record)=>record.idEmpresa == value},
                    { title: 'Rut Empresa', scopedSlots:{ customRender: 'rutEmpresa' }, width: '20%', align: 'center', ellipsis: true, onFilter: (value, record)=>record.rutEmpresa == value},
                    { title: 'Enviar a Revisión', scopedSlots: { customRender: 'enviar' }, width: '20%', align: 'center', ellipsis: true},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, width: '25%', ellipsis: false},
                ]

            

            };
        },
        created: function () {
        },
        beforeMount() {
            
        },
        methods: {
            cambioPaginaEmpresas: function(){

            },
            expandirEmpresa: function(){

            },
        },
    };
</script>