<template>
    <div>
        <CRow>
            <CCol md="5">
                <FiltroTrabajadores ref="filtroTrab" 
                    :empresaSelected="empresa"
                    :tipoDocSelected="tipoDocumentoSelected"
                    :mesSelected="mesSelected"
                    :anioSelected="anioSelected"
                    @seleccionarTrab="filtrarTrabajadores($event, empresa)"
                />
            </CCol>
            <CCol md="3">
                <OpcionesFiltrosTrabajadores 
                    :opcionesFiltrosTrabajadores="empresa.opcionesFiltrosTrabajadores"
                    @seleccionarFiltro="seleccionFiltro($event)"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol md="12">
                
                <CargandoInfo :mostrarCarga="isLoadingTrabajadores" :mensajeInfo="'Obteniendo Trabajadores'" />
                <a-table
                    v-show="!isLoadingTrabajadores"
                    :indentSize="10"
                    row-key="rutTrabajador"
                    :locale="localeTrabajadores"
                    :columns="columnasTrabajadores"
                    :data-source="trabajadores"
                    :pagination="paginacion"
                    :scroll="{ y: 400 }"
                    size="small"
                    @change="handleTableChange">
                        <span slot="rutTrabajador" slot-scope="trabajador">
                            {{$utils.formatearRut(trabajador.rutTrabajador)}}
                        </span>
                        <span slot="tituloDoc">
                            <span class="text-truncate" style="overflow:hidden">Dctos.</span>
                            <span>({{totalTrabajadoresDocumentos}}/{{totalTrabajadores}})</span>
                        </span>
                        
                </a-table>
            </CCol>
        </CRow>
    </div>
    
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from "@/const";
    import Multiselect from "vue-multiselect";
    import axios from "axios";
    import apiUrls from '@/views/utils/apiUrls'
    import CargandoInfo from '@/views/utils/CargandoInfo'
    
    import FiltroTrabajadores from "../FiltroTrabajadoresComponente";
    import OpcionesFiltrosTrabajadores from "../filtros/OpcionesFiltrosTrabajadores";

    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    import { freeSet } from "@coreui/icons";

    export default {
        name: "TablaTrabajadores",
        freeSet,
        props: {
            totalTrabajadoresDocumentos: Number,
            totalTrabajadores: Number,
            paginacion: Object,
            empresa: Object,

            tipoDocumentoSelected: Object,
            mesSelected: Object,
            anioSelected: Object,

            portContador: Boolean,
            periodoCerrado: Boolean,
            loading: Boolean,
            isLoadingGenerarDocumento: Boolean,
            revision: Object,
            mostrarPeriodo: Boolean
        },
        components: {
            Multiselect,
            'Modal': VueModal,
            CargandoInfo,
            FiltroTrabajadores,
            OpcionesFiltrosTrabajadores,
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port+"://" + cons.ipServer,
                
                empresaSelModal: null,
                trabajadorSelectedModal: {},
                documentoSelectedModal: null,
                mostrarVisorPDF: false,

                trabajadores: [],
                isLoadingTrabajadores: false,
                trabajadoresFiltrados: [],
                opcionSeleccionadaFiltro: [],
                

                columnasTrabajadores: [
                    { title: 'Rut', scopedSlots:{ customRender: 'rutTrabajador' }, key: 'rutTrabajador', width: '10%', ellipsis: true},// width: '10%'
                    { title: 'Trabajador', dataIndex: 'nombreTrabajador', key: 'nombreTrabajador',  width: '30%', ellipsis: true},// width: '20%'
                    //{ title: 'Última Generación', key: 'generacion', scopedSlots: { customRender: 'generacion' }, width: '20%', align: 'center', ellipsis: true},
                    //{ title: 'Estado Dcto.', key: 'estadoDocumento', scopedSlots: { customRender: 'estadoDocumento' }, width: '10%', align: 'center', ellipsis: false},
                    { slots: {title: 'tituloDoc'}, key: 'documento', scopedSlots: { customRender: 'documento' }, ellipsis: false, width: '20%', align: 'center'},// width: '10%'
                    {title: "Enviar Docto", scopedSlots: { customRender: 'envioCorreo' }, width: "40%", align: "center"}// width: '20%'
                ],
                localeTrabajadores: {
                    emptyText: ' '
                },

            

            };
        },
        created: function () {
        },
        beforeMount() {
            this.getTrabajadores()
        },
        methods: {
            getTrabajadores: function(){
                    console.log("probando")
                    console.log(`${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + this.empresa.idEmpresa}/${(this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.anioSelected != null) ? this.anioSelected.valor : 'null'}`)
                    
                    this.isLoadingTrabajadores = true
                    let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + this.empresa.idEmpresa}/${(this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.anioSelected != null) ? this.anioSelected.valor : 'null'}`
                    axios({
                        method: "POST", 
                        "url": url,
                        "data": {
                            "filtrarPorPeriodo": this.mostrarPeriodo,
                            "paginacionTrabajadores": {
                                "actual": 1,
                                "tamanioPagina": 10,
                                "filtrosTrabajadores": this.trabajadoresFiltrados,
                                "filtros": this.opcionSeleccionadaFiltro
                            }
                        },
                        "headers": {
                            'Authorization': `${this.tokenLogin}`
                        }
                    }).then(result => {
                        console.log(result)
                        this.trabajadores = result.data.trabajadores
                        
                        this.$refs.filtroTrab.getTrabajadores()

                    }, error => {
                        this.mostrarNotificaciones(3, "Ups, Tuvimos un problema al obtener los Documentos, si el problema persiste contacte con nuestro soporte, Gracias.")
                    })
                    .finally(() => {
                        this.isLoadingTrabajadores = false
                    });
                
            },
            filtrarTrabajadores: function(evento){
                this.trabajadoresFiltrados = evento
                this.getTrabajadores(true, this.empresa)
                
            },
            seleccionFiltro: function(evento){
                this.opcionSeleccionadaFiltro = evento
                this.getTrabajadores(true, this.empresa)
            },
            handleTableChange: function(pagination, filters, sorter, filasActuales){
                //pagination.empresa.paginacion = pagination;
                
                this.$emit('cambioPagina', pagination)
            },

        },
    };
</script>


<style scoped>
</style>