<template>
    <div>
        <CRow class="mt-3">
            <CCol v-show="empresas.length<=0" sm="12" md="12" lg="12">
                <CCard accent-color="secondary">
                    <CCardHeader class="text-center">
                        <CRow>
                            <CCol>Seleccione un periodo para mostrar información</CCol>
                        </CRow>
                    </CCardHeader>
                </CCard>
            </CCol>
            <CCol sm="11" md="11" lg="11" v-bind:key="empresa.codEmpresa" v-for="empresa in empresas">
                <CCard accent-color="secondary" class="mt-1 mb-1">
                    <CCardHeader style="align-items: center;">
                        <div class="card-header-actions"
                        :content="'Total trabajadores: '+empresa.infoEmpresa.totalTrabajadores"
                        v-tippy="{ 
                            placement: 'top',
                            arrow: true, 
                            arrowType: 'round', 
                            animation:'fade',
                            theme: 'google',
                            followCursor : true,
                        }">
                            <CLink  class="card-header-action btn-minimize float-right" @click="desplegarEmpresa(empresa)">
                                <CIcon :name="`cil-chevron-${empresa.desplegada ? 'bottom' : 'top'}`"/>
                            </CLink>
                        </div>

                        <CRow>
                            <CCol><!--Datos empresa-->
                                <span style="vertical-align: middle;">
                                    <a style="text-decoration: none; color:#0b7689" class="ml-1 mr-1"
                                        content="Ver Info Empresa"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                        @click="mostrarInfoEmpresa(empresa)">
                                        <!--<CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />-->
                                        <span class="pi pi-info-circle" style="font-size: 1rem; color:#0b7689"></span>
                                    </a>
                                </span>
                                <span style="vertical-align: middle;">({{empresa.codEmpresa}}) {{empresa.nombreEmpresa}} </span>
                                <span style="vertical-align: middle;">({{$utils.formatearRut(empresa.rutEmpresa)}})</span> 
                            </CCol>
                            <!--<CCol>
                                {{$utils.formatearRut(empresa.rutEmpresa)}}
                            </CCol>-->
                            <CCol>
                                <CDropdown
                                    color="drpdwn-menu"
                                    class="m-0 d-inline-block"
                                    size="sm">
                                    <template #toggler-content>
                                        <CIcon :content="$options.freeSet.cilApplications" />
                                    </template>
                                    <div role="group">
                                        <CDropdownHeader class="font-weight-bold">
                                            <span class="pi pi-info-circle" style="font-size: 1rem; color:#0b7689"></span> No hay informacion para ser Aceptada
                                        </CDropdownHeader>
                                    </div>
                                    <div role="group">
                                        <CDropdownHeader class="font-weight-bold">
                                            Pensar que colocar
                                        </CDropdownHeader>
                                        <CDropdownItem v-show="usaPortalEmpresa">
                                            <span v-show="empresa.userNotificar.length == 0" style="color:red;">!</span> 
                                            {{empresa.estado==3?'Volver a Enviar':'Enviar a Revisión'}}
                                        </CDropdownItem>
                                        
                                        <CDropdownItem 
                                            v-show="!empresa.enviandoEmpresa && (empresa.estado!=1 && empresa.estado!=3) && empresa.infoEmpresa.totalTrabajadores > 0 && !empresa.enviarEmpresa">
                                            Aceptar
                                        </CDropdownItem>
                                    </div>
                                    <CDropdownItem >Opcion2</CDropdownItem>
                                    <CDropdownItem >Cancelar revision Empresa</CDropdownItem>
                                </CDropdown>
                            </CCol>
                            <CCol>
                                <CDropdown class="float-left" :caret="false">
                                    <template #toggler>
                                        <CLink class="card-header-action btn-setting">
                                            <CIcon :content="$options.freeSet.cilSettings" />
                                        </CLink>
                                    </template>
                                    <div role="group">
                                        <CDropdownHeader>Titulo</CDropdownHeader>
                                        <CDropdownItem >
                                            <CIcon :content="$options.freeSet.cilDescription" class="mr-2" /> Ver
                                        </CDropdownItem>
                                        <CDropdownItem >
                                            <CIcon :content="$options.freeSet.cilCloudDownload" class="mr-2" /> Descargar
                                        </CDropdownItem>
                                        <CDropdownDivider />
                                    </div>
                                </CDropdown>
                            </CCol>
                            <CCol>
                                <span>
                                    <CBadge
                                        class="m-1"
                                        style="font-size:0.7rem; font-weight: normal;"
                                        :content="(empresa.estado==1)?'En Revisón':(empresa.estado==2)?'Rechazado '+ empresa.mensajeRespuesta+' ('+$utils.formatFechaEnDistancia(empresa.fechaMensaje)+')':(empresa.estado==3)?'Aceptado':'Sin Enviar'"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google' 
                                        }"
                                        shape="pill" :color="(empresa.estado==1)?'info':(empresa.estado==2)?'danger':(empresa.estado==3)?'success':'secondary'">
                                        
                                        <CIcon v-if="empresa.estado==1" style="width:20px; height:20px;" :content="$options.freeSet.cilArrowCircleRight" />
                                        <CIcon v-else-if="empresa.estado==2" style="width:20px; height:20px;" :content="$options.freeSet.cilXCircle" />
                                        <CIcon v-else-if="empresa.estado==3" style="width:20px; height:20px;" :content="$options.freeSet.cilCheckCircle" />
                                        <CIcon v-else style="width:20px; height:20px;" :content="$options.freeSet.cilBan" />
                                    </CBadge>
                                    <CBadge
                                        v-show="empresa.periodoCerrado"
                                        class="m-1"
                                        :content="`Periodo Cerrado ${empresa.fechaPeriodoCerrado==undefined || empresa.fechaPeriodoCerrado== ''?'por aplicación Edig':$utils.formatFechaEnDistancia(empresa.fechaPeriodoCerrado)}`"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google' 
                                        }"
                                        
                                        shape="pill" 
                                        style="font-size:0.7rem; font-weight: normal; background-color:#29235C; color:white">
                                        <CIcon style="width:20px; height:20px;" :content="$options.freeSet.cilLockLocked" />
                                    </CBadge>
                                    
                                    <CButton
                                        v-show="tipoDocSelected.valor !='contratos' && !empresa.periodoCerrado && empresa.estado==3"
                                        shape="pill"
                                        content="Cerrar Periodo" 
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                        }"
                                        style="font-size:11px;padding:1px"
                                        class="btnPrincipalVapp"
                                        @click="cerrarPeriodo(empresa)">
                                        <CIcon style="width:20px; height:20px;" :content="$options.freeSet.cilDoor" />
                                    </CButton>
                                    <CButton
                                        v-show="empresa.periodoCerrado && empresa.estado==3"
                                        shape="pill"
                                        content="Abrir Periodo" 
                                        v-tippy='{ placement : "left" }'
                                        style="font-size:11px;padding:1px"
                                        class="btnPrincipalVapp"
                                        @click="abrirPeriodo(empresa)">
                                        <CIcon style="width:20px; height:20px;" :content="$options.freeSet.cilRoom" />
                                    </CButton>
                                </span>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCollapse :show="empresa.desplegada" :duration="400">
                        <CCardBody>
                            <CRow v-show="!empresa.isLoadingTrabajadores">
                                <CCol md="12">
                                    <TblTrabajadores
                                        :empresa="empresa"
                                        :tipoDocumentoSelected="tipoDocSelected"
                                        :mesSelected="mesSelected"
                                        :anioSelected="anioSelected"
                                        :totalTrabajadoresDocumentos="empresa.totalTrabajadoresDocumentos"
                                        :totalTrabajadores="empresa.totalTrabajadores"
                                        :paginacion="empresa.paginacion"
                                        :ref="'tblTrabajadores'+empresa.codEmpresa" 
                                    />
                                    <!--<TblTrabDocumentos
                                        :totalTrabajadoresDocumentos="empresa.totalTrabajadoresDocumentos"
                                        :totalTrabajadores="empresa.totalTrabajadores"
                                        
                                        :paginacion="empresa.paginacion"
                                        
                                    />-->
                                </CCol>
                            </CRow>
                            <CRow>
                            <CCol sm="11" md="11" lg="11">
                                <CPagination
                                    size="sm"
                                    align="end"
                                    :active-page.sync="currentPage"
                                    :pages="10"
                                    responsive
                                />
                            </CCol>
                        </CRow>
                        </CCardBody>
                    </CCollapse>
                    
                </CCard>
            </CCol>

        </CRow>
        
        <ModalInfoEmpresa 
            :mostrarModal="infoEmpresaModal"
            :empresa="empresaSeleccionadaInfo"
            :webPadre="this"
        />
        <!--<CRow class="mt-3">
            <CCol v-show="empresasFiltradas.length<=0" sm="11" md="11" lg="11">
                <CCard accent-color="secondary">
                    <CCardHeader>
                        <CRow>
                            <CCol>{{locale.emptyText}} 
                            </CCol>
                        </CRow>
                    </CCardHeader>
                </CCard>
            </CCol>
            <CCol sm="11" md="11" lg="11" v-bind:key="empresa.codEmpresa" v-for="empresa in empresasFiltradas">
                <CCard accent-color="secondary" class="mt-1 mb-1">
                    <CCardHeader>
                        <div class="card-header-actions">
                            <CLink  v-show="empresa.codEmpresa == '4'" class="card-header-action btn-minimize float-right" @click="isCollapsed = !isCollapsed">
                                <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`"/>
                            </CLink>
                            
                        </div>

                        <CRow>
                            <CCol>({{empresa.codEmpresa}}) {{empresa.nombreEmpresa}} [{{empresa.infoEmpresa.totalTrabajadores}}] 
                            </CCol>
                            <CCol>
                            {{formatearRut(empresa.rutEmpresa)}}
                            </CCol>
                            <CCol>
                                <CDropdown 
                                    
                                    color="drpdwn-menu"
                                    class="m-0 d-inline-block"
                                    size="sm">
                                    <template #toggler-content>
                                        <CIcon :content="$options.freeSet.cilApplications" />
                                    </template>
                                    <CDropdownItem >Opcion1</CDropdownItem>
                                    <CDropdownItem >Opcion2</CDropdownItem>
                                    <CDropdownItem >Opcion3</CDropdownItem>
                                </CDropdown>
                            </CCol>
                            <CCol>
                                <CDropdown class="float-left" :caret="false">
                                    <template #toggler>
                                        <CLink class="card-header-action btn-setting">
                                            <CIcon :content="$options.freeSet.cilSettings" />
                                        </CLink>
                                    </template>
                                    <div role="group">
                                        <CDropdownHeader>Titulo</CDropdownHeader>
                                        <CDropdownItem >
                                            <CIcon :content="$options.freeSet.cilDescription" class="mr-2" /> Ver
                                        </CDropdownItem>
                                        <CDropdownItem >
                                            <CIcon :content="$options.freeSet.cilCloudDownload" class="mr-2" /> Descargar
                                        </CDropdownItem>
                                        <CDropdownDivider />
                                    </div>
                                </CDropdown>
                            </CCol>
                        </CRow>
                    </CCardHeader>
                    <CCollapse :show="isCollapsed" :duration="400">
                        <CCardBody>
                            TRABAJADORES AQUÍ
                        </CCardBody>
                    </CCollapse>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" md="11" lg="11">
                <CPagination
                size="sm"
                align="end"
                :active-page.sync="currentPage"
                :pages="10"
                responsive
                />
            </CCol>
        </CRow>-->
    </div>
</template>
<script>
    import cons from "@/const";
    import axios from "axios";
    import apiUrls from '@/views/utils/apiUrls'

    import TblTrabajadores from "./tablasComponentes/TablaTrabajadores";
    import ModalInfoEmpresa from "./ModalInfoEmpresa";

    //import TblTrabDocumentos from "./componentes/TablaTrabajadoresDocumentos";//mejorarTabla

    import { freeSet } from "@coreui/icons";

    export default {
        name: "FormatoListaEmpresas",
        freeSet,
        props: {
            empresas: Array,
            tipoDocSelected: Object,
            mesSelected: Object,
            anioSelected: Object
        },
        components: {
            TblTrabajadores,
            ModalInfoEmpresa
            //TblTrabDocumentos
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port+"://" + cons.ipServer,
                regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
                
                currentPage: 1,
                
                empresaSeleccionadaInfo: {},
                infoEmpresaModal: false,
            

            };
        },
        created: function () {},
        beforeMount() {},
        methods: {            
            mostrarInfoEmpresa: function(empresa){
                this.empresaSeleccionadaInfo = empresa.infoEmpresa
                this.infoEmpresaModal = true
            },
            desplegarEmpresa: function(empresa){
                empresa.desplegada = !empresa.desplegada
                //this.obtenerTrabajadores(expanded, record)
                let key = 'tblTrabajadores'+empresa.codEmpresa
                this.$refs[key][0].getTrabajadores(empresa.desplegada, empresa)
            },
            /*obtenerTrabajadores: function(expanded, empresa){
                if(expanded){
                    if(empresa.trabajadoresFiltrados.length > 0 ){
                        empresa.txtEnviarTodos = "Enviar Documentos a Trabajadores Seleccionados"
                        empresa.txtActualizarDocs = "Actualizar Documentos Trabajadores Seleccionados"
                        empresa.txtDescargarDocumentos = "Descargar Documentos Trabajadores Seleccionados"
                    } else{
                        empresa.txtEnviarTodos = empresa.txtEnviarTodosResp
                        empresa.txtActualizarDocs = "Actualizar Documentos"
                        empresa.txtDescargarDocumentos = "Descargar Documentos"
                    }

                    empresa.isLoadingTrabajadores = true
                    let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + empresa.idEmpresa}/${(this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}`
                    
                    axios({
                        method: "POST", 
                        "url": url,
                        "data": {
                            "filtrarPorPeriodo": this.mostrarPeriodo,
                            "paginacionTrabajadores": {
                                "actual": empresa.paginacion.current,
                                "tamanioPagina": empresa.paginacion.pageSize,
                                "filtrosTrabajadores": empresa.trabajadoresFiltrados,
                                "filtros": empresa.opcionSeleccionadaFiltro
                            }
                        },
                        "headers": {
                            'Authorization': `${this.tokenLogin}`
                        }
                    }).then(result => {

                        empresa.trabajadores = result.data.trabajadores
                        empresa.totalTrabajadoresDocumentos = result.data.totalDocumentosTrab
                        empresa.totalTrabajadores = result.data.totalTrabajadores

                        if(empresa.totalTrabajadoresDocumentos >0){
                            empresa.hayDocumentosTrabajador = true
                        }
                        const pagination = { ...empresa.paginacion };
                        pagination.total = result.data.totalTrabajadores
                        pagination.empresa = empresa
                        empresa.paginacion = pagination

                        this.$refs.filtroTrab.getTrabajadores()

                    }, error => {
                        this.mostrarNotificaciones(3, "Ups, Tuvimos un problema al obtener los Documentos, si el problema persiste contacte con nuestro soporte, Gracias.")
                    })
                    .finally(() => {
                        empresa.isLoadingTrabajadores = false
                    });
                }
            },*/
            cambioPagina: function(){console.log("test")}
        },
    };
</script>